<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Bill Number</th>
                    <th class="text-right">Bill Amount</th>
                    <th>Party Name</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in purchase" :key="i">
                    <td> {{ item.date }}</td>
                    <td> {{ item.bill_number }}</td>
                    <td class="text-right"> {{ item.bill_amount }}</td>
                    <td> {{ item.party_name }}</td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm p-0"
                        @click="goToReturnPage(item.id)"
                        style="cursor: pointer; margin-left: 10px"
                        v-if="! ['returned', 'partially_returned'].includes(item.status)"
                      >
                        <i class="far fa-window-close" style="font-size: 1.3em; margin: 5px 10px"></i>
                      </button>
                      <button
                        class="btn btn-danger btn-sm"
                        @click="goToReturnPage(item.id, true)"
                        style="cursor: pointer; margin-left: 10px"
                        v-if="item.status !== 'returned'"
                      >
                        <i class="fas fa-undo-alt"></i>
                      </button>
                      <button class="btn btn-secondary btn-sm"
                              @click="goToPrint(item.id)"
                              style="cursor: pointer; margin-left: 10px"
                      >
                        <i class="fas fa-print"></i>
                      </button>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!purchase.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import handlePurchase from "@/services/modules/purchase";
import useDate from "@/services/utils/day";
import handleBarcode from "@/services/modules/barcode";
import pdfPrinter from "@/services/utils/pbPdfPrinter";
import handleCompany from "@/services/modules/company";

const props = defineProps({
  purchase: {
    type: Array,
    default: []
  },
  companyInfo: {
    type: Object,
    default: null
  }
})

const router = useRouter();
const route = useRoute();
const store = useStore()
const { fetchPurchaseBill } = handlePurchase()
const { formatDate} = useDate()
const { fetchBarcode } = handleBarcode();
const { exportToPDF } = pdfPrinter();
const { fetchCompanyInfo } = handleCompany();

const goToReturnPage = (billId, isPartial = false) => {

  const params = {
    companyId : route.params.companyId,
    menuId : route.params.menuId,
    pageId : route.params.pageId,
    billId : billId,
  }

  const name = isPartial ? 'purchase-bill-partial-return' : 'purchase-bill-return';

  router.push({name, params, query: route.query})
}

const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;
  let barcodeQuery = "";
  let qrcode = null
  let barcode = null
  let purchaseBill = null

  fetchPurchaseBill(id, companyQuery).then( (res) =>{
    if( res ){
      purchaseBill = res.data;
      let qrcodeData = JSON.stringify({
        ref_no: purchaseBill.bill_number,
        ref_date: purchaseBill.date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery = `?code=${purchaseBill.bill_number}&qrcode=${qrcodeData}`;
    }}).then (() => {
      fetchBarcode(barcodeQuery).then( (res) => {
        barcode = res.barcode;
        qrcode = res.qrcode
      }).then( () => {
        exportToPDF(props.companyInfo, purchaseBill, barcode, qrcode, user.name, 1)
      })
    }).catch( (err) => {
      console.log(err);
    })
}


</script>
